import styled from 'styled-components'

export default styled.div`
  width: 100%;
  .label-material {
    font-family: 'Prompt-SemiBold', sans-serif;
    color: rgba(31, 45, 57, 0.3);
    font-size: 14px;
    margin: 0px;
  }
  .text-input-container {
    position: relative;
    input,
    textarea {
      height: 40px;
      font-family: 'Prompt', sans-serif;
      width: 100%;
      border: solid 1px ${(props) => props.theme.greyDisabled};
      border-radius: 3px;
      padding: 10px 12px;
      outline: none;
      font-size: 16px;
      &.disabled {
        background: #f8f8f8;
        border: none;
        align-items: center;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: normal;
        color: #4a4a4a;
        opacity: 1;
        color: rgba(74, 74, 74, 1);
        -webkit-text-fill-color: #4a4a4a;
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: ${(props) => props.theme.placeholderColor};
          opacity: 0.54;
          font-size: 16px;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          color: ${(props) => props.theme.placeholderColor};
          opacity: 0.54;
          font-size: 16px;
        }
        &:hover {
          cursor: not-allowed;
        }
      }
      &:focus {
        border: 1px solid ${(props) => props.theme.jadeGreen};
      }
      &.is-error {
        border: solid 1px ${(props) => props.theme.orangeFont};
      }
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-family: 'Prompt', sans-serif;
        color: ${(props) => props.theme.placeholderColor};
        font-size: 16px;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        font-family: 'Prompt', sans-serif;
        color: ${(props) => props.theme.placeholderColor};
        font-size: 16px;
      }
    }
    &.material {
      input,
      textarea {
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid #4a4a4a;
        padding: 0px;
        font-family: 'Prompt', sans-serif;
        font-weight: 400;
        font-size: 20px;
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font-family: 'Prompt', sans-serif;
          color: ${(props) => props.theme.placeholderColor};
          font-weight: 400;
          font-size: 16px;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          font-family: 'Prompt', sans-serif;
          color: ${(props) => props.theme.placeholderColor};
          font-weight: 400;
          font-size: 16px;
        }
        &:disabled {
          background: white;
        }
      }
      + .error-text-container {
        font-size: 12px;
      }
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .icon-container {
      position: absolute;
      top: 8px;
      right: 8px;
      .icon-wrapper {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
  .label-loading-icon {
    -webkit-animation: rotation 2s infinite linear;
    margin-left: 5px;
    @keyframes rotation {
      from {
        -webkit-transform: rotate(359deg);
      }
      to {
        -webkit-transform: rotate(0deg);
      }
    }
  }
  .error-text-container {
    position: absolute;
    margin-top: 2px;
    font-size: 10px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${(props) => props.theme.redFont};
  }
`
