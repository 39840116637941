import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import TextInputWrapper from './styledComponent'
import LabelInput from '../LabelInput/Loadable'

const MATERIAL_DESIGN_MODE = 'material-design'

export default class TextInput extends PureComponent {
  getLabel() {
    let output = null
    const { label } = this.props
    if (label) {
      const className = this.props.mode === MATERIAL_DESIGN_MODE ? 'label-material' : ''
      output = (
        <div>
          <LabelInput className={className} text={label} />
        </div>
      )
    }
    return output
  }

  getTextArea() {
    let className = this.props.className
    className += this.props.errorText ? ' is-error' : ''
    return (
      <textarea
        id={this.props.id}
        className={className}
        ref={this.props.passRef ? (ref) => this.props.passRef(ref) : null}
        placeholder={this.props.placeHolder}
        style={this.props.style}
        onChange={this.props.onChange ? (e) => this.props.onChange(e, e.target.value) : null}
        value={this.props.value}
        onKeyPress={this.props.onKeyPress ? (e) => this.props.onKeyPress(e) : null}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        maxLength={this.props.maxLength}
        min={this.props.min}
        max={this.props.max}
      />
    )
  }

  getInput() {
    let className = this.props.className
    className += this.props.errorText ? ' is-error' : ''
    if (this.props.disabled) {
      className += ' disabled'
    }
    return (
      <input
        id={this.props.id}
        ref={this.props.passRef ? (ref) => this.props.passRef(ref) : null}
        type={this.props.type}
        placeholder={this.props.placeHolder}
        className={className}
        style={this.props.style}
        onChange={
          this.props.onChange
            ? (e) => {
                this.props.onChange(e, e.target.value)
              }
            : null
        }
        value={this.props.value}
        onKeyPress={this.props.onKeyPress ? (e) => this.props.onKeyPress(e) : null}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        maxLength={this.props.maxLength}
        min={this.props.min}
        max={this.props.max}
      />
    )
  }

  render() {
    const input = this.props.type === 'textarea' ? this.getTextArea() : this.getInput()
    const className = this.props.errorText ? 'error-text-container' : 'error-text-container hide'
    const inputMode = this.props.mode === MATERIAL_DESIGN_MODE ? 'text-input-container material' : 'text-input-container'
    return (
      <TextInputWrapper>
        {this.getLabel()}
        <div className={inputMode}>{input}</div>
        <div className={className}>{this.props.errorText}</div>
      </TextInputWrapper>
    )
  }
}

TextInput.defaultProps = {
  disabled: false,
  type: 'text',
  mode: 'normal',
  readOnly: false
}

TextInput.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  errorText: PropTypes.string,
  placeHolder: PropTypes.string,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  passRef: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  mode: PropTypes.string,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number
}
